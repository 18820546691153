import { TICKER_SEARCH_API_BASE } from "src/api";

/**
 *
 * @param {*} tickerSearchTerm string
 * @param {*} n number
 * @returns ['fb', 'goog'] array of string
 */
async function getTickersBySearchTerm(tickerSearchTerm, n = 50) {
    let tickers = [];
    try {
        const params = new URLSearchParams();
        params.append("n", n);
        if (tickerSearchTerm) {
            params.append("p", tickerSearchTerm);
        }
        const url = `${TICKER_SEARCH_API_BASE}?${params.toString()}`;
        const response = await fetch(url);
        if (response.status === 200) {
            const result = await response.json();
            tickers = result.tickers.map((t) => t.ticker);
        }
    } catch (err) {
        console.error("err :>> ", err);
    }
    return tickers;
}

export async function getIpoTickers() {
    /**
     * {
     *  RECENT_IPO_TICKERS: [],
     *  UPCOMING_IPO_TICKERS: [],
     *  EXPIRY: number
     * }
     */
    let IPO_TICKERS = window.localStorage.getItem("IPO_TICKERS");

    const json = JSON.parse(IPO_TICKERS);

    let expiryInterval = 0;
    const normalExpiryInterval = 1 * 24 * 3600 * 1000;
    const shortExpiryInterval = 1800 * 1000;

    let recentIpoTickers = json?.RECENT_IPO_TICKERS,
        upcomingIpoTickers = json?.UPCOMING_IPO_TICKERS;
    const ipo_term = "__ipo__";
    if (!IPO_TICKERS || json?.expiry <= Date.now()) {
        const [recent, thisWk, nextWk] = await Promise.all([
            getTickersBySearchTerm(ipo_term, 50),
            getTickersBySearchTerm(ipo_term, 50),
            getTickersBySearchTerm(ipo_term, 50),
        ]);
        recentIpoTickers = recent;
        upcomingIpoTickers = [...thisWk, ...nextWk];
        expiryInterval = normalExpiryInterval;
        if (recentIpoTickers.length + upcomingIpoTickers.length === 0) {
            // It's likely the backend has a transient issue.
            expiryInterval = shortExpiryInterval;
        }
        IPO_TICKERS = JSON.stringify({
            expiry: Date.now() + expiryInterval,
            RECENT_IPO_TICKERS: recentIpoTickers,
            UPCOMING_IPO_TICKERS: upcomingIpoTickers,
        });
        window.localStorage.setItem("IPO_TICKERS", IPO_TICKERS);
    }

    return { recentIpoTickers, upcomingIpoTickers };
}
